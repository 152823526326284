<script>
export default {
  props: {
    tag: {
      default: 'h2',
      required: false,
      type: String,
    },
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        class: 'subheadline',
      },
      this.$slots.default
    )
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.subheadline {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 24px;

  @media all and (min-width: vars.$screen-width-767) {
  }
}
</style>
